<template>
  <div class="container py-3">
    <a-card :bordered="false" class="ant-pro-components-tag-select">
      <a-form layout="inline">
        <a-input-search placeholder="请输入区域或地址开始查询资产" style="width: 50%" v-model="address" @search="onSearch"
          :allowClear="true" />
        <standard-form-row title="房源位置" block style="padding-bottom: 11px;padding-top: 20px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="district">
              <tag-select-option value="11">上海市区</tag-select-option>
              <tag-select-option value="22">金山区</tag-select-option>
            </tag-select>
          </a-form-item>
          <a-form-item v-show="districtList.includes('22')">
            <tag-select v-model="town">
              <tag-select-option value="1">朱泾镇</tag-select-option>
              <tag-select-option value="2">枫泾镇</tag-select-option>
              <tag-select-option value="3">张堰镇</tag-select-option>
              <tag-select-option value="4">亭林镇</tag-select-option>
              <tag-select-option value="5">吕巷镇</tag-select-option>
              <tag-select-option value="6">廊下镇</tag-select-option>
              <tag-select-option value="7">金山卫镇</tag-select-option>
              <tag-select-option value="8">漕泾镇</tag-select-option>
              <tag-select-option value="9">山阳镇</tag-select-option>
              <tag-select-option value="10">石化街道</tag-select-option>
            </tag-select>

          </a-form-item>
        </standard-form-row>

        <!-- <standard-form-row title="房屋类型" block style="padding-bottom: 11px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="houseType">
              <tag-select-option value="1">商铺</tag-select-option>
              <tag-select-option value="2">写字楼</tag-select-option>
              <tag-select-option value="3">综合体</tag-select-option>
              <tag-select-option value="4">厂房</tag-select-option>
              <tag-select-option value="5">其他商业</tag-select-option>
              <tag-select-option value="6">住宅</tag-select-option>
            </tag-select>
          </a-form-item>
        </standard-form-row> -->

        <standard-form-row title="房屋面积" block style="padding-bottom: 11px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="floorSpace">
              <tag-select-option value="1">＜100㎡</tag-select-option>
              <tag-select-option value="2">100-300㎡</tag-select-option>
              <tag-select-option value="3">300-500㎡</tag-select-option>
              <tag-select-option value="4">500-1000㎡</tag-select-option>
              <tag-select-option value="5">＞1000㎡</tag-select-option>
            </tag-select>
          </a-form-item>
        </standard-form-row>
      </a-form>
    </a-card>

    <div class="text-center mx-auto pb-1" data-wow-delay="0.2s" style="max-width: 800px;margin-top: 20px;">
                    <h1 class="display-5 mb-1">精选商铺</h1>
                </div>

    <div class="container-fluid blog py-1" style="margin-top: 30px;margin-bottom: 30px;">
      <div v-if="spinValue" style="display: flex; justify-content: center; align-items: center; height: 900px;">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 50px" />
        </a-spin>
      </div>
      <div v-else>
        <div v-if="houseEntity.length !== 0">
        <div style="display: flex;
           justify-content: center; /* 水平居中 */
           align-items: center;     /* 垂直居中 */">
          <div style="width: 100%;">
            <!-- <p style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 12px;
                    ">
            </p> -->
            <HouseItem :houseEntity="houseEntity"></HouseItem>
            <a-pagination style="margin-top: 20px;float: right;" :current="pagination.currentNum" :defaultPageSize="12"
              :total="pagination.total" @change="onChange" />
          </div>
        </div>
      </div>
      <a-empty v-else :description="false" :image-style="{
        height: '400px',
      }">
        <div class="" style="
                  font-size: 18px;
                  margin: 4px 20px;
                  font-weight:bold;
                  color: #101d37;
                  font-family: Helvetica;
                  display: inline-block;
                ">
          暂无房源
        </div>
      </a-empty>
      </div>
      
    </div>
  </div>
</template>

<script>
import HouseItem from '@/components/property/HouseItem'
import StandardFormRow from '@/components/StandardFormRow'
import TagSelect from '@/components/TagSelect'
import { recommendHouse } from "@/request/api";
const TagSelectOption = TagSelect.Option

export default {
  components: {
    TagSelect,
    TagSelectOption,
    StandardFormRow,
    HouseItem
  },
  data() {
    return {
      district: {}, //房源位置
      districtList: [],
      town: {}, // 金山区内房源位置（子数据）
      townList: [],
      houseType: {}, //房屋类型
      houseTypeList: [],
      floorSpace: {}, //房屋面积
      floorSpaceList: [],
      address: "",
      houseEntity: [],
      pagination: {
        currentNum: 1,
        total: 1
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      spinValue: true
    }
  },
  mounted() {
    this.searchHouse()
  },
  watch: {
    district(newVal) {
      this.pagination.currentNum = 1
      this.districtList = this.screen(newVal, this.districtList, ["11", "22"])
      this.searchHouse()
    },
    town(newVal) {
      this.pagination.currentNum = 1
      this.townList = this.screen(newVal, this.townList, ["1","2", "3", "4", "5", "6", "7", "8", "9", "10"])
      this.searchHouse()
    },
    // houseType(newVal) {
    //   this.pagination.currentNum = 1
    //   this.houseTypeList = this.screen(newVal, this.houseTypeList, ["1", "2", "3", "4", "5", "6"])
    //   this.searchHouse()
    // },
    floorSpace(newVal) {
      this.pagination.currentNum = 1
      this.floorSpaceList = this.screen(newVal, this.floorSpaceList, ["1", "2", "3", "4", "5"])
      this.searchHouse()
    }
  },
  methods: {
    //用于多选框的参数筛选
    screen(newVal, parmList, initList) {
      if (newVal.checked == true) {
        if (newVal.value == "total") {
          parmList = initList
        }
        else {
          parmList.push(newVal.value)
        }
      }
      if (newVal.checked == false) {
        if (newVal.value == "total") {
          parmList = []
        }
        else {
          parmList = parmList.filter(item => item !== newVal.value)
        }
      }
      return parmList;
    },
    //查询房源
    searchHouse() {
      this.spinValue = true
      var placeTown = []
      if (this.districtList.includes('11')) {
        placeTown.push("11")
      }
      if (this.districtList.includes('22')) {
        if (this.townList.length == 0) {

          placeTown.push(...["1","2", "3", "4", "5", "6", "7", "8", "9", "10"])
        }
        else {
          placeTown.push(...this.townList)
        }
      }
      recommendHouse({
        address: this.address,
        placeTown,
        houseType: [1],
        floorSpaceStatus: this.floorSpaceList,
        page: this.pagination.currentNum,
        pageSize: 12,
      }).then((res) => {
        this.houseEntity = res.data.data;
        this.pagination.currentNum = res.data.pageNo
        this.pagination.total = res.data.total
        this.spinValue = false
      });
    },
    onSearch() {
      this.pagination.currentNum = 1
      this.searchHouse()
    },
    onChange(current) {
      this.pagination.currentNum = current;
      this.searchHouse()
    },
  }
}
</script>

<style lang="less" scoped>
.ant-pro-components-tag-select {
  /deep/ .ant-pro-tag-select .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px;
  }
}

.list-articles-trigger {
  margin-left: 12px;
}
</style>